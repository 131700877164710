exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-committee-tsx": () => import("./../../../src/pages/committee.tsx" /* webpackChunkName: "component---src-pages-committee-tsx" */),
  "component---src-pages-coverage-tsx": () => import("./../../../src/pages/coverage.tsx" /* webpackChunkName: "component---src-pages-coverage-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-pass-privacy-tsx": () => import("./../../../src/pages/pass-privacy.tsx" /* webpackChunkName: "component---src-pages-pass-privacy-tsx" */),
  "component---src-pages-shinkan-tsx": () => import("./../../../src/pages/shinkan.tsx" /* webpackChunkName: "component---src-pages-shinkan-tsx" */)
}

